import Space from '@rebass/space'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
import { useQueryParam, ModalStateParam } from 'use-query-params'
import Button from '../components/Button'
import Card from '../components/Card'
import CompanyLogos from '../components/CompanyLogos'
import Container from '../components/Container'
import { Flex, Box } from 'reflexbox'
import GradientWrapper from '../components/GradientWrapper'
import SEO from '../components/SEO'
import { ThemeConsumer } from 'styled-components'
import MainHighlights from '../components/MainHighlights'
import SecondaryHighlights from '../components/SecondaryHighlights'
// import Tippy from '@tippyjs/react'
// import 'tippy.js/dist/tippy.css'
// import 'tippy.js/themes/light.css'

// icons
import LogoUI8 from '../images/vector/logo-ui8.svg'
import LogoSmashicons from '../images/vector/smashicons.svg'
import LogoGumroad from '../images/vector/gumroad.svg'
import LogoCraftwork from '../images/vector/craftwork.svg'
import LogoVectopus from '../images/vector/logo-vectopus.svg'
import UsecaseDesigners from '../images/vector/usecase-designers.svg'
import UsecaseEngineers from '../images/vector/usecase-engineers.svg'
import UsecaseMarketeers from '../images/vector/usecase-marketeers.svg'

const StyledTestimonialCard = styled(Card)`
  border-color: #f1dcb1;
  background-color: #fdedcc;
  box-shadow: none;

  &:hover {
    background-color: #fdedcc;
    box-shadow: none;
  }
`

const IndexPage = ({ data }) => {
  const [modalState, setModalState] = useQueryParam(
    'thank-you',
    ModalStateParam
  )

  function analyticsDownloadTrial() {
    setModalState('true')
  }

  return (
    <ThemeConsumer>
      {theme => (
        <>
          <SEO title="IconJar • Your icon organizer for macOS" />

          <Container my={[4, 5]}>
            <Box textAlign={'center'}>
              <h1>The go-to app for managing your icon library</h1>
              <p className="subtitle">
                Say goodbye to scattered icons and hello to simple organization
                one click away!
              </p>

              <Space mx={2} mb={[3]}>
                <Button
                  as="a"
                  // href="/download"
                  onClick={analyticsDownloadTrial}
                  colorscheme="white"
                  big="true"
                  className="xs-hide">
                  Download free trial
                </Button>
                <Button as={Link} to="/pricing" colorscheme="green" big="true">
                  View pricing
                </Button>
              </Space>
              <Box
                style={{ width: '1264px', maxWidth: '100%' }}
                mt={3}
                mx={'auto'}>
                <Img
                  fluid={
                    theme.appearance === 'light'
                      ? data.hero.childImageSharp.fluid
                      : data.heroDark.childImageSharp.fluid
                  }
                  alt="IconJar app UI"
                  className="app-preview"
                />
              </Box>
            </Box>
          </Container>

          <Container>
            <Flex flexWrap="wrap" justifyContent="center">
              <Box width={[1, 3 / 4]} mb={[5, 6]}>
                <CompanyLogos />
              </Box>
            </Flex>
          </Container>

          <GradientWrapper
            pt={[4, 5]}
            style={{
              maxWidth: '100%',
              overflowX: 'hidden',
              marginTop: '-48px'
            }}>
            <Container>
              {/* Drag + drop, Export, SVG optimizer */}
              <MainHighlights />

              {/* Smart sets, Recently used, Starred*/}
              <SecondaryHighlights />
            </Container>

            <Container mt={[4, 5]} mb={[5, 6]}>
              <Flex flexWrap="wrap">
                <Box width={[1, 1 / 3]} px={[0, 3, 3]} mb={[5, 0]}>
                  <Space pb={[3]}>
                    <h2>Did you say niche? We don't think so.</h2>
                  </Space>
                  <Flex justifySelf="stretch" mb={3}>
                    <Box width={'15%'}>
                      <UsecaseDesigners />
                    </Box>
                    <Box width={'85%'}>
                      <h6>Designers</h6>
                      <p>
                        Ensure that your icon collection doesn't block your
                        creative process.
                      </p>
                    </Box>
                  </Flex>
                  <Flex justifySelf="stretch" mb={3}>
                    <Box width={'15%'}>
                      <UsecaseEngineers />
                    </Box>
                    <Box width={'85%'}>
                      <h6>Engineers</h6>
                      <p>
                        Get rid of that bottleneck in the development process.
                      </p>
                    </Box>
                  </Flex>
                  <Flex justifySelf="stretch" mb={3}>
                    <Box width={'15%'}>
                      <UsecaseMarketeers />
                    </Box>
                    <Box width={'85%'}>
                      <h6>Marketeers</h6>
                      <p>
                        Use icons efficiently to create beautiful marketing
                        content.
                      </p>
                    </Box>
                  </Flex>
                </Box>
                <Box width={[1, 2 / 3]} px={[0, 3, 3]} mb={[4, 0]}>
                  <Flex flexWrap="wrap">
                    <Box width={[1, 1 / 2]} mb={3}>
                      <StyledTestimonialCard p={3} mx={[0, 2]}>
                        <p className="handwritten">
                          Very handy even as a starting point for composing /
                          planning out visuals and thinking around what
                          iconography is needed.
                        </p>
                        <p className="small faded handwritten">
                          Rosemary Lynch &bull; Jan 10, 2023
                        </p>
                      </StyledTestimonialCard>
                    </Box>
                    <Box width={[1, 1 / 2]} mb={3}>
                      <StyledTestimonialCard p={3} mx={[0, 2]}>
                        <p className="handwritten">
                          If you do any design work you need a good icon
                          library, and this is a good way to keep and maintain
                          one. Key feature: you can associate licenses with sets
                          of icons.
                        </p>
                        <p className="small faded handwritten">
                          Mathew Murphy &bull; Jul 25, 2023
                        </p>
                      </StyledTestimonialCard>
                    </Box>
                    <Box width={[1, 1 / 2, 1 / 3]} mb={3}>
                      <StyledTestimonialCard p={3} mx={[0, 2]}>
                        <p className="handwritten">
                          works perfectly, AWESOME FOR MY WORKFLOW
                        </p>
                        <p className="small faded handwritten">
                          Beck Graben &bull; Jan 26, 2023
                        </p>
                      </StyledTestimonialCard>
                    </Box>
                    <Box width={[1, 1 / 2, 1 / 3]} mb={3}>
                      <StyledTestimonialCard p={3} mx={[0, 2]}>
                        <p className="handwritten">
                          Great program, saves me the hassle of having to go to
                          each icon library's website
                        </p>
                        <p className="small faded handwritten">
                          Nick &bull; Apr 27, 2023
                        </p>
                      </StyledTestimonialCard>
                    </Box>
                    <Box width={[1, 1 / 2, 1 / 3]} mb={3}>
                      <StyledTestimonialCard p={3} mx={[0, 2]}>
                        <p className="handwritten">
                          Easy to use and stable app. This is like Typeface for
                          icons.
                        </p>
                        <p className="small faded handwritten">
                          Roland &bull; Apr 27, 2023
                        </p>
                      </StyledTestimonialCard>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </Container>
          </GradientWrapper>

          <Container mb={[5, 6]}>
            <Flex flexWrap="wrap" alignItems="center" mx={2}>
              <Box width={1} mb={[2]} textAlign={'center'}>
                <h6>We're supported by:</h6>
              </Box>
              <Box width={[1, 1 / 2, 1 / 3]} mb={3}>
                <a href="https://www.ui8.net/?rel=icnjr">
                  <Card
                    as={Flex}
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      height: '133px',
                      backgroundColor: '#2B292B'
                    }}
                    mx={2}
                    animateonhover="true">
                    <LogoUI8 width="48" height="48" />
                  </Card>
                </a>
              </Box>
              <Box width={[1, 1 / 2, 1 / 3]} mb={3}>
                <a href="https://iconfinder.com">
                  <Card
                    as={Flex}
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      height: '133px',
                      backgroundColor: '#14984D'
                    }}
                    mx={2}
                    animateonhover="true">
                    <Img
                      fixed={data.logoIconfinder.childImageSharp.fixed}
                      alt="Iconfinder logos"
                    />
                  </Card>
                </a>
              </Box>
              <Box width={[1, 1 / 2, 1 / 3]} mb={3}>
                <a href="https://smashicons.com">
                  <Card
                    as={Flex}
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      height: '133px',
                      backgroundColor: '#2a2d2d'
                    }}
                    mx={2}
                    animateonhover="true">
                    <LogoSmashicons width="64" height="64" />
                  </Card>
                </a>
              </Box>

              <Box width={[1, 1 / 2, 1 / 3]} mb={[3, 3, 0]}>
                <a href="https://discover.gumroad.com/design/icons?a=581296979">
                  <Card
                    as={Flex}
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      height: '133px',
                      backgroundColor: '#FFC900'
                    }}
                    mx={2}
                    animateonhover="true">
                    <LogoGumroad width="128" />
                  </Card>
                </a>
              </Box>
              <Box width={[1, 1 / 2, 1 / 3]} mb={[3, 3, 0]}>
                <a href="https://craftwork.design/?ref=333">
                  <Card
                    as={Flex}
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      height: '133px',
                      backgroundColor: '#ececec'
                    }}
                    mx={2}
                    animateonhover="true">
                    <LogoCraftwork width="128" />
                  </Card>
                </a>
              </Box>
              <Box width={[1, 1 / 2, 1 / 3]} mb={[3, 3, 0]}>
                <a href="https://vectopus.com">
                  <Card
                    as={Flex}
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      height: '133px',
                      backgroundColor: '#ffd00f'
                    }}
                    mx={2}
                    animateonhover="true">
                    <LogoVectopus width="128" />
                  </Card>
                </a>
              </Box>
            </Flex>
          </Container>

          <GradientWrapper pt={[5, 6]} style={{ backgroundSize: '100%' }}>
            <Container textAlign={'center'} mb={[5, 6]}>
              <Box
                style={{ width: '128px', marginTop: '-8px' }}
                mx="auto"
                mb={3}>
                <Img
                  fixed={data.appIcon.childImageSharp.fixed}
                  alt="IconJar app icon"
                />
              </Box>
              <h2>Let's get started</h2>
              <Space mx={2} mt={2} mb={[3, 0]}>
                <Button
                  as="a"
                  // href="/download"
                  onClick={analyticsDownloadTrial}
                  colorscheme="white"
                  big="true"
                  className="xs-hide">
                  Download free trial
                </Button>
                <Button as={Link} to="/pricing" colorscheme="green" big="true">
                  View pricing
                </Button>
              </Space>
            </Container>
          </GradientWrapper>
        </>
      )}
    </ThemeConsumer>
  )
}

export default IndexPage

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "hero@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1264, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    heroDark: file(relativePath: { eq: "hero-dark@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1264, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    appIcon: file(relativePath: { eq: "app-icon@2x.png" }) {
      childImageSharp {
        fixed(width: 128, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    inspectorScreenshot: file(relativePath: { eq: "inspector@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1151, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    inspectorScreenshotDark: file(
      relativePath: { eq: "inspector-dark@2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1151, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    starredScreenshot: file(relativePath: { eq: "starred@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1151, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    starredScreenshotDark: file(relativePath: { eq: "starred-dark@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1151, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    logoIconfinder: file(relativePath: { eq: "logo-iconfinder@2x.png" }) {
      childImageSharp {
        fixed(width: 151, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    featureAnnouncement: file(
      relativePath: { eq: "updates/announcement-2.8.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 252, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    featureAnnouncementDark: file(
      relativePath: { eq: "updates/announcement-2.8-dark.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 252, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
